import Api from '@/services/Api'

export default {
  updateOrder(orderId, cartItems) {
    return Api().put(`/api/partner/updateOrder/${orderId}`, cartItems)
  },
  updateOrderStatus(orderId, orderStatus) {
    return Api().put(`/api/partner/updateOrderStatus/${orderId}`, orderStatus)
  },
  createOrder(orderInfo) {
    return Api().post('/api/partner/createOrder', orderInfo)
  },
  getAllCustomerOrdersByStatus(orderStatus) {
    return Api().get(`/api/partner/getAllCustomerOrdersByStatus/${orderStatus}`)
  },
  getCustomerPastOrders(customerId) {
    return Api().get(`/api/partner/getCustomerPastOrders/${customerId}`)
  },
  viewCustomerOrderDetails(orderInfo) {
    return Api().post('/api/partner/viewCustomerOrderDetails', orderInfo)
  },
  updateOrderPayment(orderPayment) {
    return Api().put('/api/partner/updateOrderPayment', orderPayment)
  },
  getAllCustomerOrders() {
    return Api().get(`/api/partner/getAllCustomerOrders`)
  },
  getCustomerOrder(orderId){
    return Api().get(`/api/partner/getCustomerOrder/${orderId}`)
  },
  raiseRefund(orderInfo) {
    return Api().post('/api/partner/payments/raiseRefund', orderInfo)
  },
  updatePaymentStatus(orderInfo) {
    return Api().post('/api/partner/payments/updateStatus', orderInfo)
  }
}