<template>
    <div>
      <CircularProgress v-if="loader"/>

      <div class="mt-16" v-if="!ordersAvailable" >
          <v-card class="d-flex justify-center mr-2 mt-16" color="#373535" dark elevation="0">
              <v-list color="#373535">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-16" size="90"> $notAvailableIcon </v-icon>
              </span>
              <span class="d-flex justify-center mt-5 cardTitle">There are no orders available.</span>
              </v-list>
          </v-card>
        </div>

        <v-slide-group center-active class="mt-2 mb-5" v-if="ordersAvailable">
              <v-slide-item
                  v-for="(cat,index) in cats"
                  :key="index"
                  v-slot="{ active, toggle }"
              >
                  <div
                  :color="active ? 'primary' : 'grey lighten-1'"
                  class="mx-2"
                  @click="toggle"
                  >
                    <v-btn @click="selectTab(cat)" :class="activeCat===cat?'btnActive':'btnInactive'" class="mt-4 v-tab text-capitalize" small color="#434242" block dark elevation="0">{{cat.replace('_',' ')}}</v-btn>
                  </div>
              </v-slide-item>
        </v-slide-group>
        <div class="my-5" :key="orderItem.orderId" v-for="orderItem in ordersList">
          <PaymentItem :key="orderItem.orderId" v-if="typeof orderItem.orderPaymentDetails.status !== 'undefined' && orderItem.orderPaymentDetails.status==activeCat" :orderItem="orderItem" />
        </div>
    </div>
</template>
<script>
import orderServices from '@/services/orderServices'
import PaymentItem from './PaymentItem.vue'
import CircularProgress from '../loaders/CircularProgress.vue'
  export default {
    name: 'PaymentMain',
    components: {
       PaymentItem,
       CircularProgress
    },
    data () {
        return {
            loader: false,
            ordersList: '',
            ordersAvailable: false,
            title: '',
            cats:['not_paid','paid'],
            activeCat: 'not_paid'
        }
    },
    methods: {
        selectTab( index ){
            this.activeCat = index;
        },
    },
    async mounted () {
        this.loader = true
        const getAllActiveOrders = (await orderServices.getAllCustomerOrders()).data;
        if (getAllActiveOrders.activeOrderList.length !=0) {
          this.ordersList = getAllActiveOrders.activeOrderList
          this.ordersAvailable = true
        } else {
          this.ordersList = ''
          this.ordersAvailable = false
        }
        this.loader = false
    }
  }
</script>
<style scoped>
.cardTitle{
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 12px;
color: #C7C6C6;
}
.catalog_title {
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 14px;
line-height: 46px;
color: rgba(255, 255, 255, 0.8);
}
.notAvailableText {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 14px;
color: #9B9A9A;
}
.btnText {
text-transform: unset !important;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 26px;
color: rgba(255, 255, 255, 0.5);
}
.v-tab {
    padding: 20px !important;
    border-radius: 0 !important;
}
.v-tab.btnActive {
    background: #7a7a7a !important;
    color: #fff !important;
    border-bottom: 2px solid white !important;
}
.text-capitalize {
    text-transform: capitalize !important;
}
</style>
