<template>
    <div>
    <v-card class="mt-2" dark max-width="400" elevation="0">
        <v-list color="#434242">
            <v-list-item  @click="toEditPayment(orderItem.orderId)">
                <v-list-item-content>
                    <v-list-item-title class="customerName">{{orderItem.customerName}}</v-list-item-title>
                    <span class="listTitle mt-2">
                      Order No: {{orderItem.orderSeriesNo}}
                    </span>
                    <span class="mt-4 capitaliseText"><v-chip small color="#4B9C6D">{{orderItem.orderStatus}}</v-chip></span>
                </v-list-item-content>

                <v-list-item-content class="text-right">
                    <span class="houseNo">{{orderItem.customerHouseNo}}</span>
                    <span class="listContent">{{orderItem.customerCommunityName}}</span>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
    </div>
</template>
<script>
import moment from 'moment';
import router from '@/router';

  export default {
    name: 'PaymentItem',
    props: {
     orderItem: Object
    },
    data: () => ({
        moment: moment,
    }),
    methods: {
        updateStatus (status) {
            const updateOrder = {
                status: status,
                orderId: this.orderItem.orderId
            }
            this.$emit('update-status', updateOrder)
        },
        toEditPayment ( id ) {
            router.push('edit-payment/'+id)
        }
    }
  }
</script>
<style scoped>
.dividerLine{
color: rgba(255, 255, 255, 0.5);
}
.capitaliseText {
font-family: Lato;
font-style: regular;
font-weight: 500;
font-size: 14px;
line-height: 0px;
color: #ffffff;
text-transform: capitalize;
}
.customerName{
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
color: #FFFFFF;
}
.listTitle {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 14px;
line-height: 0px;
color: #ffffff;
}
.listContent {
margin-top: 20px;
font-family: Lato;
font-style: regular;
font-weight: 500;
font-size: 14px;
line-height: 0px;
color: #ffffff;
}

.houseNo {
font-family: Montserrat;
font-style: normal;
font-weight: 900;
font-size: 16px;
color: #FFFFFF;
}

.houseText {
font-family: Montserrat;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #FFFFFF;
}

.addToBasketBtn {
text-transform: unset !important;
background: #4B9C6D;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #FFFFFF;
}
.cancelBtn {
text-transform: unset !important;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #E14B4B;
}
.editBtn {
text-transform: unset !important;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #F2BA55;
}
</style>
